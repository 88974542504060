import React from "react"
import Layout from "../../components/Layout"
import Helmet from "react-helmet"

const ContactIndexPage = () => {
  return (
    <Layout>
      <Helmet titleTemplate="%s | Dreamjot">
        <title>Contact</title>
        <meta
          name="description"
          content="Support and frequently asked questions."
        />
        <meta name="robots" content="noindex" />
      </Helmet>{" "}
      <div className="container">
        <h1>Contact Us</h1>
        <p>
          If you have any questions about Dreamjot, please contact us by sending
          an email to support@ubiquitools.com.
        </p>
      </div>
    </Layout>
  )
}

export default ContactIndexPage
